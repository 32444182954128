import React from 'react';
import Header from "../components/Header/Header";
import Nav from '../components/Nav/Nav';
import ParticipateForm from '../components/ParticipateForm/ParticipateForm';
import { graphql } from 'gatsby';
import get from 'lodash/get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const isBrowser = typeof window !== "undefined";

export default class Participate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isDesktop: true,
      participateFormOpen: false,
    }
  }

  setDisplaySettings = () => {
    if (isBrowser) {
      this.setState({
        isDesktop: window.innerWidth > 1200
      })
    }
  }

  componentDidMount() {
    this.setDisplaySettings();
    window.addEventListener('resize', this.setDisplaySettings);
    this.setState({loading: false})
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDisplaySettings);
  }

  render () {
    const participatePageContent = JSON.parse(get(this, 'props.data.contentfulPage.pageContent.raw'))
    if (this.state.loading) {
      return (
        <>
        <Header/>
        <div className="wrapper">
          <Nav />
        </div>
        </>
      )
    } else {
      if (this.state.isDesktop) {
        return (
          <>
          <Header/>
          <div className="wrapper">
          <Nav />
          <div className="splitpage-container">
            <div className="participate-left-side splitpage-left-side">
              <ParticipateForm isDesktop={true} />
            </div>
            <div className="participate-right-side splitpage-right-side">
              {documentToReactComponents(participatePageContent)}
            </div>
          </div>
        </div>
        </>
        )
      } else {
        return (
          <>
          <Header/>
          <div className="wrapper">
          <Nav />
            <div className={this.state.participateFormOpen ? "participate-form-mobile" : "participate-form-mobile hidden"}>
              <div className="participate-form-close" onClick={() => this.setState({ participateFormOpen: false }) }>
                <div></div>
                <div></div>
              </div>
              <ParticipateForm isDesktop={false} />
            </div>
            <div className="participate-mobile-header">
              <h2>Participate</h2>
              <div className="participate-form-toggle" onClick={() => this.setState({ participateFormOpen: true }) }>
                <p>Submit</p>
              </div>
            </div>
            <div className="participate-mobile-description">
              {documentToReactComponents(participatePageContent)}
            </div>
        </div>
        </>
        )
      }
    }
  }
}

export const participateQuery = graphql`
  {
    contentfulPage(pageTitle: {eq: "Participate"}) {
      pageContent {
        raw
      }
    }
  }`;