import * as React from "react";
import { useForm, ValidationError } from '@formspree/react';

const ParticipateForm = (props) => {
  const [state, handleSubmit] = useForm("xayvbalp");
  
  const desktopHeader = () => {
    if (props.isDesktop) {
      return <h1>Participate</h1>
    } else {
      return;
    }
  }

  const submittingCover = () => {
    if (state.submitting) {
      return (
        <div className="form-submit-loading">
          <h1>Submitting...</h1>
        </div>
      )
    }
  }

  const autosizeTextarea = e => {
    setTimeout(function(){
      e.target.style.cssText = 'height:auto; padding:0';
      e.target.style.cssText = 'height:' + e.target.scrollHeight + 'px';
    },0);
  }
  
  if (state.succeeded) {
    return (
      <h1 className="form-success-message">Thank you for your submission! (We’ll be in touch shortly.)</h1>
    )
  } else {
    return (
      <>
      {submittingCover()}
      {desktopHeader()}
      <form method="post" action="#" onSubmit={handleSubmit}>
        <input required type="text" name="Name" id="name" maxlength="256" />
        <label htmlFor="Name">Name</label>
        <ValidationError 
          prefix="Name" 
          field="name"
          errors={state.errors}
          className="form-error"
        />
        <input required type="email" name="Email" id="email" maxlength="256" />
        <label htmlFor="Email">Email</label>
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
          className="form-error"
        />
        {/* <input required type="text" name="Message" id="message" maxlength="256" /> */}
        <textarea required name="message" id="message" rows="1" maxlength="256" onKeyDown={autosizeTextarea} />
        <label htmlFor="Message">What do you hope to gain from participating?</label>
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
          className="form-error"
        />
        <button type="submit"><div className="circle"></div>submit</button>
        </form>
        </>
    )
  }
}

export default ParticipateForm;